/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        controller = new ScrollMagic.Controller();
        var isLateralNavAnimating       = false,
            $hero_text                  = $('.hero-text'),
            $template_wrap              = $('.site-wrap'),
            $document                   = $('body,html'),
            $body                       = $('body'),
            $li                         = $('li'),
            $header                     = $('.header'),
            $psst_logo                  = $('.psst--logo'),
            $language_list              = $('.language--list'),
            $language_list_item         = $('.wpml-ls-item'),
            $gn_trigger                 = $('.gn--trigger'),
            $gn_wrapper                 = $('.gn--wrapper'),
            $gn_nav_item_has_children   = $('.gn--list .menu-item.menu-item-has-children'),
            $gn_sub_nav_trigger         = $('.gn--list .menu-item.menu-item-has-children > a'),
            $gn_nav_icon                = $('<i class="absolute-pos icon-arrow-right"></i>'),
            $gn_list                    = $('.gn--list'),
            $gn_nav_item                = $('.gn .gn--list > li'),
            $gn_sub_nav_icon            = $('.gn .gn--list > li i'),
            $gn_sub_nav                 = $('.gn .gn--list li .sub-menu'),
            $gn_sub_nav_items           = $('.gn .gn--list li .sub-menu li'),
            $gn_sub_nav_active          = $('.gn .gn--list li.active .sub-menu li.active'),
            $header_link_form           = $('.link-to-form'),
            $testimony_img              = $('.testimony--bg'),
            $full_testimony_trigger     = $('.testimony--trigger'),
            $testimony_excerpt          = $('.testimony--excerpt'),
            $testimony                  = $('.testimony--full'),
            $accordion_list             = $('.accordion--list'),
            $accordion_list_item        = $('.accordion--item'),
            $accordion_alt_sections     = $('.accordion--list.cb-2, .accordion--list.cb-4'),
            $accordion_btn              = $('.accordion--list.std .list-heading'),
            $accordion_content          = $('.accordion--content-wrap'),
            $accordion_close_icon       = $('.accordion--list.std .accordion--item .list-heading'),
            $csp_program_list           = $('.csp-programs .csp-list .accordion--item .accordion--content-wrap .list-style-bullet'),
            width                       = $(window).width(),
            fadeHeaderBackground,
            fade_banner_text;


        //Execute functions
        triggerNavigation();
        globalSubNavigation();
        navigationAccordion();
        testimonyReadMore();
        responsiveTestimonyBg();
        changeClass();
        accordion();
        accordionRepsonsiveSize();
        onWindowResize();
        $(window).resize(onWindowResize);


        //====================================//
        // Global Navigation
        //====================================//
        $($gn_nav_item_has_children).prepend($gn_nav_icon);

        function triggerNavigation() {
          $($gn_trigger).on('click', function(event){
            event.preventDefault();
            if($($body).hasClass('gn--open')) {
               $($header_link_form).removeClass('hide-link');
               //If the user has scrolled on the menu, scroll to top on collapse
                setTimeout(function() {
                  $($gn_wrapper).delay(1000).scrollTop(0);
                }, 1000);
            } else {
              $($header_link_form).addClass('hide-link');
              $($gn_nav_item).removeClass('sub-nav-open');
              $($gn_sub_nav).slideUp(100);
              $($gn_nav_item).children().removeClass('icon-active');
              $('#trp-floater-ls-current-language').addClass('hide-lang');
              $('#trp-floater-ls').addClass('is-hidden');

              if($($gn_sub_nav_active).length > 0 ) {
                $($gn_sub_nav_active).parents($gn_sub_nav).slideDown(0);
                $($gn_sub_nav_active).parents('li').addClass('sub-nav-open');
                $($gn_sub_nav_active).parent($gn_sub_nav).prevUntil($gn_sub_nav_icon).addClass('icon-active');
                $($gn_sub_nav_active).parent($gn_sub_nav).children($gn_sub_nav_items).addClass('fade-li');
              }
            }
            $($body).toggleClass('gn--open');
            $($psst_logo).fadeToggle(500);
          });
        }

        $('.gn-nav--close').on('click', function(event) {
          event.preventDefault();
          $('#trp-floater-ls-current-language').removeClass('hide-lang');
          $('#trp-floater-ls').removeClass('is-hidden');
            $($header_link_form).removeClass('hide-link');
               //If the user has scrolled on the menu, scroll to top on collapse
                setTimeout(function() {
                  $($gn_wrapper).delay(1000).scrollTop(0);
                }, 1000);

            $($body).toggleClass('gn--open');
            $($psst_logo).fadeToggle(500);
          });


        function globalSubNavigation() {
          $($gn_sub_nav_trigger).on('click', function(event) {
            event.preventDefault();
            // If the global navigation is open and the current list item is not selected/expanded
            if( $($body).hasClass('gn--open') && !$(this).parents($gn_nav_item).hasClass('sub-nav-open')) {
              //remove class for conditionals
              $($gn_nav_item).removeClass('sub-nav-open');
              $($gn_sub_nav_items).removeClass('fade-li');
              //if the target is not already open, slide everything up
              if(width < 768) {
                $($gn_sub_nav).slideUp(500);
              } else {
                $($gn_sub_nav).fadeOut(0);
              }
              //remove the animation class from the icon
              $($gn_nav_item).children().removeClass('icon-active');
              //add the conditional to the target item
              $(this).parent($gn_nav_item).addClass('sub-nav-open');
              //slide the target down
              if(width < 768) {
                $(this).next($gn_sub_nav).slideDown(500);
              } else {
                $(this).next($gn_sub_nav).fadeIn();
              }
              // Add opacity and animation class
              $(this).next($gn_sub_nav).children($gn_sub_nav_items).addClass('fade-li');
              //add an animation class to the icon
              $(this).prev($gn_sub_nav_icon).addClass('icon-active');
            } else {
              $(this).parent($gn_nav_item).removeClass('sub-nav-open');
              $(this).prev($gn_sub_nav_icon).removeClass('icon-active');
              $($gn_sub_nav_items).removeClass('fade-li');
              if(width < 768) {
                $($gn_sub_nav).slideUp(500);
              } else {
                $($gn_sub_nav).fadeOut(0);
              }
            }
          });
        }



        function navigationAccordion() {
          var $nav_accordion            = $('.accordion--list.global'),
              $nav_accordion_list_item  = $('.accordion--list.global .accordion--item'),
              $nav_accordion_btn        = $('.org-name'),
              $accordion_content        = $('.accordion--content-wrap');

          $($nav_accordion_btn).on('click', function(event) {
            event.preventDefault();
            if($(this).parent($li).hasClass('expand-nav')) {
              $(this).parent($li).removeClass('expand-nav');
              $(this).addClass('icon-plus-round');
              $(this).parent($li).find($accordion_content).slideUp(500);
            } else {
              $($nav_accordion_btn).removeClass('icon-minus-round').addClass('icon-plus-round');
              $($accordion_content).slideUp(500);
              $(this).parent($li).addClass('expand-nav');
              $(this).parent($li).find($accordion_content).slideDown(500);
              $(this).addClass('icon-minus-round').removeClass('icon-plus-round');
            }
          });
        }



        function testimonyReadMore() {
          $($full_testimony_trigger).on('click', function(event) {
            event.preventDefault();
            width = $(window).width();
            $($testimony_excerpt).addClass('hide-exerpt');
            $($testimony).addClass('show-testimony');
            setTimeout(function() {
              theOffset = $($testimony).offset();
              if(width <= 767) {
                $($document).animate({ scrollTop: theOffset.top - 120 });
              } else if (width <=991 ) {
                $($document).animate({ scrollTop: theOffset.top - 300 });
              } else {
                $($document).animate({ scrollTop: theOffset.top - 200 });
              }
            }, 450);
          });
        }

        //====================================//
        // Search
        //====================================//
        var $searchTrigger = $('.search-trigger-wrap');

        $searchTrigger.on('click', function(e) {
          e.preventDefault();
          $('.hide-on-search').toggleClass('hide');
          $('.global-search').toggleClass('is-showing');
        });

        //====================================//
        // Language Navigation
        //====================================//
        // var $current_lang = $('.wpml-ls-current-language'),
        //     $menu_lang_trigger = $('.gn--lang-trigger'),
        //     $menu_lang = $('.gn-language'),
        //     $menu_lang_close_btn = $('.gn-language--close');

        // $($current_lang).addClass('icon-arrow-right');

        // $($menu_lang_trigger).on('click', function(event) {
        //   event.preventDefault();
        //   $($menu_lang).addClass('gn-language--open');
        // });

        // $($menu_lang_close_btn).on('click', function(event) {
        //   event.preventDefault();
        //   $($menu_lang).removeClass('gn-language--open');
        // });


        // Force the testimony to always be 100vw
        function responsiveTestimonyBg() {
          img_width = $($body).width();
          $($testimony_img).width(img_width);
        }


        //====================================//
        // Accordion
        //====================================//
        function accordion() {
          $($accordion_btn).on('click', function(event) {
            event.preventDefault();
            // determin if we are on tablet or mobile
            if( !$(this).parents('.accordion--list').hasClass('desktop-alternative') ) {
              //Is the current selection already active (open)
              if($(this).parent($accordion_list_item).hasClass('active-accordion')) {
                $(this).parent($accordion_list_item).removeClass('active-accordion');
                $(this).siblings($accordion_content).slideUp(500);
                $(this).removeClass('icon-minus-round').addClass('icon-plus-round');
              } else {
                $(this).closest('ul').children('li').removeClass('active-accordion');
                $(this).closest('ul').children('li').children('.list-heading').siblings($accordion_content).slideUp(500);
                $($accordion_btn).removeClass('icon-minus-round').addClass('icon-plus-round');
                $(this).parent($accordion_list_item).addClass('active-accordion');
                $(this).siblings($accordion_content).slideDown(500);
                $(this).removeClass('icon-plus-round').addClass('icon-minus-round');
              }
            } else {
              if(!$(this).parent($accordion_list_item).hasClass('active-window')) {
                $($accordion_list_item).removeClass('active-window');
                $($accordion_content).fadeOut(0);
                $(this).parent($accordion_list_item).addClass('active-window');
                $(this).siblings($accordion_content).fadeIn(0);
              }
              accordionRepsonsiveSize();
            }
          });
        }

        function accordionRepsonsiveSize() {
          var $accordion_alternative = $('.accordion--list.desktop-alternative'),
              $accordion_target = ('.accordion--item.active-window .accordion--content-wrap');
          $(function() {
              $($accordion_alternative).matchHeight({
                  target: $($accordion_target)
              });
          });
        }



        /*
         |----------------------------------------------------------------
         |  Accordions
         |----------------------------------------------------------------
         */
        var $shopAccordion = $('#shops-accordion');
        var $shopTrigger = $('#shops-accordion .shop-trigger');

        $('#shops-accordion > li:eq(0) a').addClass('active').next().slideDown();

        function accordionSogi($accordionName, $trigger) {
          var $li = $('li'),
              $content = $('.content-wrap');

          $($trigger).click(function(j) {
              var dropDown = $(this).closest($li).find($content);

              $(this).closest($accordionName).find($content).not(dropDown).slideUp();

              if ($(this).hasClass('active')) {
                  $(this).removeClass('active');
              } else {
                  $(this).closest($accordionName).find('a.active').removeClass('active');
                  $(this).addClass('active');
              }

              dropDown.stop(false, true).slideToggle();

              j.preventDefault();
          });
        }

        accordionSogi($shopAccordion, $shopTrigger);

        //====================================//
        // Responsive Class Changes
        //====================================//

        function changeClass() {
          var width = $(window).width();

          if(width > 767) {
            //$($gn_list).addClass('absolute-center');
            $($csp_program_list).addClass('standard-list');
          } else {
             //$($gn_list).removeClass('absolute-center');
            $($csp_program_list).removeClass('standard-list');
          }

          if( (width > 1199) && (!$($accordion_list).hasClass('accordion-constant') ) && (!$('body').hasClass('?lang=ar'))) {
            $($accordion_alt_sections).addClass('desktop-alternative');
          } else {
            $($accordion_alt_sections).removeClass('desktop-alternative');
          }
        }


        function onWindowResize() {
          var width = $(window).width();
          changeClass();
          responsiveTestimonyBg();
          if(width < 1200) {
            $($accordion_list).matchHeight({ remove: true });
          } else {
            $($accordion_list).matchHeight({ remove: false });
            if(width > 1199) {
              accordionRepsonsiveSize();
            }
          }
        }


        //====================================//
        // Blog
        //====================================//
        var $all_categories       = $('.global__cat--all'),
            $category_list        = $('.cat--list'),
            $category_page        = $('.post-cat-page'),
            $dropdown_trigger     = $('.dropdown--trigger'),
            $dropdown_list        = $('.dropdown--list'),
            $article_grid         = $('.grid'),
            $article_item         = $('.article--item');

        if( !$($body).hasClass('blog')) {
          $($all_categories ).removeClass('active');
        }

        var $current_page = $($category_list).find('.active').text();
        console.log($current_page);

        $($category_page).html($current_page);

        $($dropdown_trigger).on('click', function(event) {
          event.preventDefault();
          if($($dropdown_list).hasClass('cat--list') ) {
            $($category_list).slideToggle(500);
          } else if($($dropdown_list).hasClass('school--names') ){
            $(this).siblings('.school--names').slideToggle(500);
          }
          $(this).toggleClass('expand');
        });

          var $grid = $($article_grid).imagesLoaded( function() {
            // init Masonry after all images have loaded
            $grid.masonry({
              itemSelector: '.grid-item',
              columnWidth: '.grid-sizer'
            });
          });

        //====================================//
        // Global Animations
        //====================================//


        fade_banner_text = new ScrollMagic.Scene({
          triggerElement: 'body',
          triggerHook:0,
          reverse: false
        })

        .setClassToggle('.move-text-down', 'fade-up-text')
        .addTo(controller);


          $($article_item).each(function(){
          var ourScene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            reverse: false
          })
          .setClassToggle(this, 'show-article')
          .addTo(controller);
          });


        function fadeInSite(){
          $($template_wrap).removeClass('fade-in');
        } setTimeout(fadeInSite, 1000);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var mobileAnimationIsActive,
            pinHomeTestimonyImage,
            fadeInTestimony,
            fadeInBlockTwo,
            blogArticle,
            blogArticleTitle,
            $content_block_2_home         = $('.cb-2--home'),
            $front_page_blog_item         = $('.blog-item'),
            $front_page_blog_header       = $('.blog-item .entry-header'),
            $hero_big_text                = $('.hero-text--big'),
            $hero_big_text_sub_head       = $('.hero-text--big .hero-subheading'),
            $resource_links               = $('.page-link--item'),
            width                         = $(window).width();


        changeClass();
        $(window).resize(onWindowResize);



        $($content_block_2_home).each(function(){
          fadeInBlockTwo = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.8,
            offset: '50px',
            reverse: false
          })
          .setClassToggle(this, 'fade-up-cb')
          .addTo(controller);
        });


        $($front_page_blog_item).each(function(){
          blogArticle = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.9,
            offset: '-100px',
            reverse: false
          })
          .setClassToggle(this, 'show-news')
          .addTo(controller);
        });

        $($front_page_blog_header).each(function(){
          blogArticleTitle = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.95,
            reverse: false
          })
          .setClassToggle(this, 'show-content')
          .addTo(controller);
        });
        //====================================//
        // Animations
        //====================================//

        function mobileScrollAnimation() {
          $($resource_links).each(function(){
          var ourScene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.7,
            offset: '50px',
            reverse: false
          })
          .setClassToggle(this, 'show-resource-list')
          .addTo(controller);
        });

          // pin the background for a parallax
          pinHomeTestimonyImage = new ScrollMagic.Scene({
            triggerElement: '.testimony--bg',
            triggerHook: "onLeave",
            duration: '75%'
          })

          .setPin('.testimony--bg', {pushFollowers: false})
          .addTo(controller);

          //Fade in the testimony
          fadeInTestimony = new ScrollMagic.Scene({
            triggerElement: '.testimony--bg',
            triggerHook: 0.6,
            offset: '330px'
          })

          .setClassToggle('.testimony-wrap', 'show-testimony-wrap')
          .addTo(controller);
        }

        function desktopScrollAnimation() {
          $($resource_links).each(function(){
          var ourScene = new ScrollMagic.Scene({
            triggerElement: '.page-link--list',
            triggerHook: 0.7,
            offset: '-20px',
            reverse: false
          })
          .setClassToggle(this, 'show-resource-list')
          .addTo(controller);
          });

          // pin the background for a parallax
          pinHomeTestimonyImage = new ScrollMagic.Scene({
            triggerElement: '.testimony--bg',
            triggerHook: 0,
            duration: '50%'
          })

          .setPin('.testimony--bg', {pushFollowers: false})
          .addTo(controller);

          //Fade in the testimony
          fadeInTestimony = new ScrollMagic.Scene({
            triggerElement: '.testimony--bg',
            triggerHook: 0.3,
            offset: '230px'
          })

          .setClassToggle('.testimony-wrap', 'show-testimony-wrap')
          .addTo(controller);
        }


        if(width < 768) {
          mobileScrollAnimation();
        } else if(width >= 768) {
          desktopScrollAnimation();
        }


        //====================================//
        // Responsive Class Layout Change
        //====================================//

        function changeClass() {
          var width = $(window).width();

          if(width > 676) {
            $($hero_big_text_sub_head).addClass('absolute-center');
          } else {
            $($hero_big_text_sub_head).removeClass('absolute-center');
          }
        }


        function onWindowResize() {
          var width = $(window).width();
          changeClass();
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Resources template
    'page_template_template_resources': {
      init: function() {
        // JavaScript to be fired on the about us page
        var m_pin_testimony_img,
            m_fade_testimony,
            m_fade_in_tips,
            m_fade_list_item,
            d_pin_testimony_img,
            d_fade_testimony,
            d_fade_in_tips,
            d_icon_list,
            d_fade_list_item,
            zoom_imgs,
            $text_list_item               = $('.text-list'),
            $icon_list_item               = $('.icon-list'),
            width = $(window).width();


        //====================================//
        // Animations
        //====================================//

        zoom_imgs = new ScrollMagic.Scene({
            triggerElement: '.cb4--img',
            triggerHook: 0.4,
            offset: '-150px'
          })

          .setClassToggle('.cb4--img' , 'cb4--img-move')
          .addTo(controller);

        function mobileScrollAnimation() {

          $($text_list_item).each(function(){
            m_fade_list_item = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.9,
              reverse: false
            })
            .setClassToggle(this, 'show-element')
            .addTo(controller);
          });

           $($icon_list_item).each(function(){
            m_icon_list = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.9,
              offset: '-175px',
              reverse: false
            })

            .setClassToggle(this, 'element-up')
            .addTo(controller);
          });

          if( !$('.testimony--bg').hasClass('one-section') ) {

            // pin the background for a parallax
            m_pin_testimony_img = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: "onLeave",
              duration: '175%'
            })

            .setPin('.testimony--bg', {pushFollowers: false})
            .addTo(controller);

            //Fade in the testimony
            m_fade_testimony = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: 0.7,
              duration: '100%',
              offset: '330px'
            })

            .setClassToggle('.testimony-wrap', 'show-testimony-wrap')
            .addTo(controller);

            //Fade in tips (section two)
            m_fade_in_tips = new ScrollMagic.Scene({
              triggerElement: '.testimony--trigger',
              triggerHook: 0.3,
              duration: '120%',
              offset: '10px'
            })

            .setClassToggle('.testimony-slide-2', 'show-slide-2')
            .addTo(controller);
          } else {

             // pin the background for a parallax
            m_pin_testimony_img = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: "onLeave",
              duration: '75%'
            })

            .setPin('.testimony--bg', {pushFollowers: false})
            .addTo(controller);

            //Fade in the testimony
            m_fade_testimony = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: 0.6,
              offset: '330px'
            })

            .setClassToggle('.testimony-wrap', 'show-testimony-wrap')
            .addTo(controller);

          }

        } // end mobile function


        function desktopScrollAnimation() {


           $($text_list_item).each(function(){
            d_fade_list_item = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.85,
              reverse: false
            })

            .setClassToggle(this, 'show-element')
            .addTo(controller);
          });



          $($icon_list_item).each(function(){
            d_icon_list = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.85,
              offset: '-30px'
            })
            .setClassToggle(this, 'element-up')
            .addTo(controller);
          });

          if( !$('.testimony--bg').hasClass('one-section') ) {

            // pin the background for a parallax
            d_pin_testimony_img = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: 0,
              duration: '150%'
            })

            .setPin('.testimony--bg', {pushFollowers: false})
            .addTo(controller);

            //Fade in the testimony
            d_fade_testimony = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: 0.3,
              duration: '70%',
              offset: '230px'
            })

            .setClassToggle('.testimony-wrap', 'show-testimony-wrap')
            .addTo(controller);

            //Fade in tips (section two)
            d_fade_in_tips = new ScrollMagic.Scene({
              triggerElement: '.testimony--trigger',
              triggerHook: 0.3,
              duration: '90%',
              offset: '50px'
            })

            .setClassToggle('.testimony-slide-2', 'show-slide-2')
            .addTo(controller);

          }else {

            // pin the background for a parallax
            d_pin_testimony_img = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: 0,
              duration: '50%'
            })

            .setPin('.testimony--bg', {pushFollowers: false})
            .addTo(controller);

            //Fade in the testimony
            d_fade_testimony = new ScrollMagic.Scene({
              triggerElement: '.testimony--bg',
              triggerHook: 0.3,
              offset: '230px'
            })

            .setClassToggle('.testimony-wrap', 'show-testimony-wrap')
            .addTo(controller);

          }
        }


         if(width < 768) {
          mobileScrollAnimation();
        } else if(width >= 768) {
          desktopScrollAnimation();
        }
      }
    },
    // Resources Flex template
    'page_template_template_flex_resources': {
      init: function() {

        // JavaScript to be fired on the about us page
        var m_pin_flex_parallax_img,
            m_fade_flex_parallax,
            m_fade_in_tips,
            m_fade_list_item,
            m_fade_list_row_item,
            d_pin_flex_parallax_img,
            d_fade_flex_parallax,
            d_fade_in_tips,
            d_icon_list,
            d_fade_list_item,
            d_fade_list_row_item,
            zoom_imgs,
            $def_list_item               = $('.def-list'),
            $def_list_row_item               = $('.def-list--row'),
            $flex_parallax_bg             = $('.flex-parallax--bg'),
            $flex_parallax_excerpt        = $('.flex-parallax--excerpt'),
            width                         = $(window).width();


        //====================================//
        // Animations
        //====================================//

        /*
        zoom_imgs = new ScrollMagic.Scene({
            triggerElement: '.cb4--img',
            triggerHook: 0.4,
            offset: '-150px'
          })

          .setClassToggle('.cb4--img' , 'cb4--img-move')
          .addTo(controller);
          */

        function mobileScrollAnimation() {

          $($def_list_item).each(function(){
            m_fade_list_item = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.9,
              reverse: false
            })
            .setClassToggle(this, 'element-up')
            .addTo(controller);
          });

           $($def_list_row_item).each(function(){
            m_fade_list_row_item = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.9,
              offset: '-175px',
              reverse: false
            })

            .setClassToggle(this, 'element-up')
            .addTo(controller);
          });

           // pin the background for a parallax
          m_pin_flex_parallax_img = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: "onLeave",
            duration: '75%'
          })

          //.setPin('.flex-parallax--bg', {pushFollowers: false})
          .setPin(this, {pushFollowers: false})
          .addTo(controller);

          //Fade in the flex_parallax
          m_fade_flex_parallax = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.6,
            offset: '330px'
          })

          .setClassToggle('.flex-parallax-wrap', 'show-flex-parallax-wrap')
          //.setClassToggle('.flex-parallax-slide-2', 'show-slide-2')
          .addTo(controller);

        } // end mobile function


        function desktopScrollAnimation() {

           $($def_list_item).each(function(){
            d_fade_list_item = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.85,
              reverse: false
            })

            .setClassToggle(this, 'element-up')
            .addTo(controller);
          });

          $($def_list_row_item).each(function(){
            d_fade_list_row_item = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.85,
              offset: '-30px'
            })
            .setClassToggle(this, 'element-up')
            .addTo(controller);
          });

          // pin the background for a parallax
          $($flex_parallax_bg).each(function(){
            d_pin_flex_parallax_img = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0,
              duration: '150%'
            })

            //.setPin('.flex-parallax--bg', {pushFollowers: false})
            .setPin(this, {pushFollowers: false})
            .addTo(controller);
          });

          //Fade in the flex_parallax
          $($flex_parallax_bg).each(function(){
            d_fade_flex_parallax = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.3,
              duration: '70%',
              offset: '230px'
            })

            .setClassToggle('.flex-parallax-wrap', 'show-flex-parallax-wrap')
            .addTo(controller);
          });

          //Fade in tips (section two)
          $($flex_parallax_excerpt).each(function(){
            d_fade_in_tips = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0.3,
              duration: '120%',
              offset: '50px'
            })

            .setClassToggle('.flex-parallax-slide-2', 'show-slide-2')
            .addTo(controller);
          });
        }


         if(width < 768) {
          mobileScrollAnimation();
        } else if(width >= 768) {
          desktopScrollAnimation();
        }
      }
    },
    // Report-it Form
    'page_template_template_report_form': {
      init: function() {
        var $document                 = $('body,html'),
            $report_it_ul             = $('.report-it__list'),
            $report_it_question       = $('.report-it--question'),
            active_question          = $('.report-it--question.active-query'),
            $incident_type            = $('#incident-type'),
            $incident_other           = $('#incident-other-type'),
            $secondary_question_1     = $('.secondary-query.sq1'),
            $secondary_question_2     = $('.secondary-query.sq2'),
            $secondary_question_3     = $('.secondary-query.sq3'),
            $secondary_question_4     = $('.secondary-query.sq4'),
            $next_question            = $('.next-question'),
            $user_feedback            = $('.user-feedback'),
            $radio_btn_witness        = $('.radio-witness'),
            $radio_btn_reported       = $('.radio-reported'),
            $radio_btn_contact_me     = $('.radio-contact'),
            $to_query_2               = $('.to-query-2'),
            $to_query_3               = $('.to-query-3'),
            $to_query_4               = $('.to-query-4'),
            $to_query_5               = $('.to-query-5'),
            $to_query_6               = $('.to-query-6'),
            $to_query_7               = $('.to-query-7'),
            $to_query_8               = $('.to-query-8'),
            $incident_location        = $('#incident-location'),
            $incident_date            = $('#incident-date'),
            $incident_parties         = $('#incident-parties'),
            $incident_description     = $('#incident-description'),
            $incident_witness_name    = $('#input_1_14'),
            $incident_reported_to     = $('#input_1_17'),
            $user_email               = $('#user-email'),
            $progres_update           = $('.question-complete'),
            $form_error_box           = $('.form-error-msg'),
            $form_error_msg           = $('.form-error-msg p'),
            $form_legal               = $('.form-legal'),
            $form_legal_terms         = $('.form-legal-terms'),
            $form_contact_me          = $('.form-contact-me'),
            $form_yes_contact         = $('#choice_1_19_1'),
            $confirm_submit           = $('#choice_1_34_1'),
            $report_it_btn            = $('.report-it-btn'),
            width                     = $(window).width();


        $('#gform_1').addClass('report-it__form');
        $('#gform_fields_1').addClass('report-it__list relative-pos');
        $('.gform_page').addClass('report-it--question');
        $('#gform_page_1_1').addClass('active-query');
        $('#field_1_1 .gfield_label').addClass('primary-query heading-Bol');
        $('.gfield_radio label').addClass('radio-label');
        $('.gfield_radio input').addClass('radio-input');
        $('#field_1_19 .gfield_checkbox label').addClass('radio-label');



        //Question One
        $('#field_1_24').addClass('secondary-query sq1');
       // var txtWrapQ1 = '<li class="report-it--question"><ul></ul></li>';
        var $incidentOther  = $('#input_1_24');
        //$incidentOther.addClass('secondary-query sq1');
        //$('#field_1_24').wrap(txtWrapQ1);
        $('#field_1_24 .gfield_label').addClass('cb--subheading');

        //Question Two
        $('#field_1_2 .gfield_label').addClass('primary-query heading-Bol');
        $('#gfield_description_1_2').addClass('query-instruction');
        $('#input_1_2').addClass('single-input');

        //Question Three
        $('#field_1_3 .gfield_label').addClass('primary-query heading-Bol');

        //Question Four
        $('#field_1_5 .gfield_label').addClass('primary-query heading-Bol');

        //Question Five
        $('#field_1_6 .gfield_label').addClass('primary-query heading-Bol');
        $('#field_1_32').addClass('secondary-query sq2b');
        $('#field_1_32 label').addClass('cb--subheading');

        //Question Six
        $('#field_1_7 .gfield_label').addClass('primary-query heading-Bol');
        $('#field_1_14').addClass('secondary-query sq2');
        $('#field_1_14 label').addClass('cb--subheading');
        $('#field_1_7 .gfield_radio label').addClass('radio-witness');

        //Question Seven
        $('#field_1_15 .gfield_label').addClass('primary-query heading-Bol');
        $('#field_1_17').addClass('secondary-query sq3');
        $('#field_1_17 label').addClass('cb--subheading');
        $('#field_1_15 .gfield_radio label').addClass('radio-reported');

        //Question Eight
        $('#field_1_19 .gfield_label').addClass('primary-query heading-Bol');
        $('#field_1_21').addClass('secondary-query sq4');
        $('#field_1_21 .gfield_label').addClass('cb--subheading');
        $('#field_1_22').addClass('secondary-query sq4');
        $('#field_1_22 .gfield_label').addClass('cb--subheading');
        $('#field_1_19 .gfield_radio label').addClass('radio-reported');
        $('#field_1_20').addClass('form-legal form-legal-terms clearfix show-checkbox');

        $('.gchoice gchoice_1_19_1 label').addClass('radio-contact');


        var $incidentType = $('#input_1_1');
        $($incidentType).on('change',function(){
          if( $(this).val() === 'Other' && $incidentOther.val().length < 7) {
            $('#field_1_24').fadeIn(0);
            $($to_query_2).removeClass('advance');
            setTimeout(function(){
              $('#field_1_24').addClass('show-query');
            }, 100);
            $.fn.matchHeight._update();
            //If the user chooses other and adds text to textarea, then selects another option, but comes back to other, have the next question btn still there
          } else if( $(this).val() === 'Other' && $incidentOther.val().length > 7){
            $('#field_1_24').fadeIn(0);
            $($to_query_2).addClass('advance');
            setTimeout(function(){
              $('#field_1_24').addClass('show-query');
            }, 100);
            $.fn.matchHeight._update();
          } else if( $(this).val() !== 'Other' && $(this).val() !== 'select your issue type' ) {
            $($to_query_2).addClass('advance');
            $('#field_1_24').fadeOut(0).removeClass('show-query');
            $.fn.matchHeight._update();
          }
        });

        //Question 1B
        $($incidentOther).on('input', function() {
          didUserType($incidentOther, $to_query_2);
        });

        //Questions 2
        var $incidentLocation = $('#input_1_2');
        $($incidentLocation).on('input', function() {
          didUserType($incidentLocation, $to_query_3);
        });

        //Questions 3
        var $incidentDate = $('#input_1_3');
        $($incidentDate).on('input', function() {
          didUserType($incidentDate,$to_query_4);
        });

        //Question 4
        var $incidentParties = $('#input_1_5');
        $($incidentParties).on('input', function() {
          didUserType($incidentParties,$to_query_5);
        });
        $("#choice_1_13_1").change(function() {
            if(this.checked) {
              $(this).parents('li').addClass('show-check');
              $("#input_1_5").val('');
              $('#field_1_28 .user-feedback').addClass('advance');
            }else{
              $(this).parents('li').removeClass('show-check');
              $('#field_1_28 .user-feedback').removeClass('advance');
            }
        });

        //Question 5
        var $incidentDesc = $('#input_1_6');
        $($incidentDesc).on('input', function() {
          didUserType($incidentDesc,$to_query_6);
          $('#field_1_32').fadeIn(0);
          setTimeout(function(){
            $('#field_1_32').addClass('show-query');
          }, 100);
        });
        //Question 6
        $('.radio-witness').on('click', function(event) {
          $('.radio-label').removeClass('radio-select');
          $(this).addClass('radio-select');
          var witnessSelected = $(this).text();
          if( witnessSelected === 'Yes') {
            $('#field_1_14').fadeIn(0);
            didUserType($incident_witness_name, $to_query_7);
            setTimeout(function(){
              $('#field_1_14').addClass('show-query');
            }, 100);
          } else {
            $($to_query_7).addClass('advance');
            $('#field_1_14').fadeOut(0).removeClass('show-query');
            $(this).addClass('radio-select');
          }
          $.fn.matchHeight._update();
        });

        $($incident_witness_name).on('input', function() {
          didUserType($incident_witness_name, $to_query_7);
        });


        //Question 7
        $('#field_1_15 .gfield_radio label').on('click', function(event) {
          $('#field_1_15 .gfield_radio label').removeClass('radio-select');
          $(this).addClass('radio-select');
          var witnessSelected = $(this).text();
          if( witnessSelected === 'Yes') {
            $('#field_1_17').fadeIn(0);
            didUserType($incident_reported_to, $to_query_8);
            setTimeout(function(){
              $('#field_1_17').addClass('show-query');
            }, 100);
            $.fn.matchHeight._update();
          } else {
            $($to_query_8).addClass('advance');
            $('#field_1_17').fadeOut(0).removeClass('show-query');
            $(this).addClass('radio-select');
            $.fn.matchHeight._update();
          }
        });

        $($incident_reported_to).on('input', function() {
          didUserType($incident_reported_to, $to_query_8);
        });

        //Question 8
        $('#input_1_19 label').on('click', function(event) {
          $('#input_1_19 label').removeClass('radio-select');
          $(this).addClass('radio-select');
          $('#field_1_34').addClass('show-checkbox');
          var contactSelect = $(this).text();
          if( contactSelect === 'Yes') {
            $('.staff-contact').addClass('show-checkbox');
            $($secondary_question_4).fadeIn(0);
            function delayAddClass(){
              $($secondary_question_4).show();
              $($secondary_question_4).addClass('show-query');
            } setTimeout(delayAddClass, 100);
            $.fn.matchHeight._update();
          } else {
            $('.staff-contact').removeClass('show-checkbox');
            $($secondary_question_4).removeClass('show-query').hide();
            $(this).addClass('radio-select');
            $($form_legal_terms ).addClass('show-checkbox');
            $($form_contact_me).removeClass('show-checkbox');
          }
        });

        //$('#gform_submit_button_1').addClass('cta-btn report-it-btn');

        $($confirm_submit).on('click' , function(){
          if ($(this).is(':checked')) {
            $('#gform_submit_button_1').addClass('show-submit');
          } else {
            $('#gform_submit_button_1').removeClass('show-submit');
          }
          resizeListHeight();
        });


        resizeListHeight();

        //Match ul to li size dynamically
        function resizeListHeight() {
          $(function() {
              $('.gform_body').matchHeight({
                  target: $('.report-it--question.active-query')
              });
          });
        }

        function didUserType(targetElement, nextQuestion ){
          if(targetElement.val().length > 5){
            $(nextQuestion).addClass('advance');
          } else {
            $(nextQuestion).removeClass('advance');
          }
        }

        $('#gform_submit_button_1').on('click', function(event) {
                //  $('#gform_target_page_number_1').val('9');
                //  $('#gform_source_page_number_1').val('9');

                  $('#gform_target_page_number_1').val('0');
                  $('#gform_source_page_number_1').val('8');

                });


        // jQuery( document ).on( 'gform_page_loaded', function( event, formId, currentPage ) {
        //     if ( currentPage == 2 ) {
        //         console.log('werwer');
        //     }
        // } );


        $($user_feedback).on('click', function(event) {
          event.preventDefault();
          $('#gform_next_button_1_9').click();
          $(this).parents($report_it_question).addClass('move-off').removeClass('active-query');
          $(this).parents($report_it_question).next().addClass('active-query');
          if (width < 768) {
            setTimeout(function() {
              theOffset = $($report_it_question).offset();
              $($document).animate({ scrollTop: theOffset.top - 70 });
            }, 250);
          }
          $('.question-complete').next().addClass('question-complete');
          resizeListHeight();
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Safe Schools Template
    'page_id_51': {
      init: function() {
        var $btn_trigger        = $('.dropdown--trigger');




            var ToggleSchoolAffilate = function(ElementID, AffiliateId, AffiliateParent){
                $("#" + ElementID).on("click", function(event) {

                    event.preventDefault();

                     var HideItemsSelector = '.'+AffiliateParent;

                     $(HideItemsSelector).children('li').each(function() {

                         console.log($(this).attr('id'));
                         if($('#' + $(this).attr('id')).hasClass('active-liaison'))
                         {
                              $('[id="' + $(this).attr('id')+'"]').each(function(){
                                  $(this).removeClass('active-liaison');
                              });
                         }
                     });




                    $('[id="'+AffiliateId + $(this).attr('id')+ '"]').each(function(){
                          $(this).addClass('active-liaison');
                    });

                     var Text = '<span> '+ $(this).html() + '</span>' +
                     '<i class="icon-chevron-down-2 absolute-center-right"></i>';

                    $(this).parent().siblings($btn_trigger).html(Text);
                    $(this).parent().slideToggle(500);

                    $($btn_trigger).removeClass('dropdown-expanded');
                    $($btn_trigger).removeClass('expand');
                });
            };

            ToggleSchoolAffilate('safe_school_span .school--names li', 'liason_item_', 'safe--liaison' );
            ToggleSchoolAffilate('substance_use_span .school--names li', 'substance_item_', 'substance-liaison');


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
